import Head from 'next/head'

const Index = () => {
  return (
    <div>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta itemProp="name" content="madatech" />
        <meta itemProp="description" content="A insurtech de uma das maiores corretoras do Brasil" />
        <meta itemProp="image" content='https://www.madatech.com.br/madatech.png' />
      </Head>
    </div>
  )
}

export default Index
